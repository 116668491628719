import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import LegalLayout from "../components/LegalLayout/LegalLayout";

function PrivacyPolicy() {
	const data = useStaticQuery(query);
	const { privacy_policy_title: title, privacy_policy_body: body } =
		data?.file?.childMarkdownRemark?.frontmatter || {};

	return <LegalLayout data={{ title, body }} />;
}

export default PrivacyPolicy;

const query = graphql`
	{
		file(name: { eq: "privacy_policy" }) {
			childMarkdownRemark {
				frontmatter {
					privacy_policy_body
					privacy_policy_title
				}
			}
		}
	}
`;
