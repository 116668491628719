import React from "react";
import PropTypes from "prop-types";

import { htmlifyMarkdown } from "../../utils";

import styles from "./LegalLayout.module.scss";

const propTypes = {
	data: PropTypes.object,
};

const defaultProps = {
	data: null,
};

function LegalLayout({ data }) {
	console.log("data:", data);

	return (
		<section className={styles.wrapper__main}>
			<h1 className={styles.title}>{data.title}</h1>
			<div
				dangerouslySetInnerHTML={{ __html: htmlifyMarkdown(data.body) }}
				className={styles.copy}
			/>
		</section>
	);
}

LegalLayout.propTypes = propTypes;
LegalLayout.defaultProps = defaultProps;

export default LegalLayout;
